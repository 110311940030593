<template>
  <div class="page">
    <div class="head-content">
      <img class="bg-img" src="@/assets/imgs/banner.png" alt="">
      <div class="title-center" ref="title">
        <div class="name-content">
          <img class="name-img" src="@/assets/imgs/name.png" alt="">
          <img class="title-img" src="@/assets/imgs/logo.png" alt="">
        </div>
        <div>
          <div class="desc">以数据、算法、工具构建快速响应的行业AI能力</div>
        </div>
      </div>
    </div>
    <div class="center-content" :style="{width:scrollWidth+'px'}">
      <div class="product-content" v-for="(item) in productList" :key="item.name">
        <product-list :item="item" :pageSize="pageSize" :scrollWidth="scrollWidth"></product-list>
      </div>
    </div>
    <div class="footer">
      <div class="section">
        <div class="icon-list">
          <div class="icon-content">
            <img class="icon1" src="@/assets/imgs/icon1.png" alt="">
          </div>
          <div class="icon-content">
            <img class="icon2" src="@/assets/imgs/icon2.png" alt="">
            <div class="name">国家高新技术企业</div>
          </div>
          <div class="icon-content">
            <img class="icon3" src="@/assets/imgs/icon3.png" alt="">
            <div class="name">ISO9001认证</div>
          </div>
        </div>
        <div class="icon-list" style="margin-left: 37px">
          <div class="icon-content">
            <img class="icon4" src="@/assets/imgs/icon4.png" alt="">
            <div class="name">中关村高新技术企业</div>
          </div>
          <div class="icon-content">
            <img class="icon5" src="@/assets/imgs/icon5.png" alt="">
            <div class="name">双软企业认证</div>
          </div>
        </div>
        <div class="menu-list">
          <div class="title">{{ menu1.name }}</div>
          <div class="menu-item" v-for="name in menu1.list" :key="name">{{ name }}</div>
        </div>
        <div class="menu-list">
          <div class="title">{{ menu2.name }}</div>
          <div class="menu-item" v-for="name in menu2.list" :key="name">{{ name }}</div>
        </div>
        <div class="code-content">
          <div class="name">联系我们</div>
          <img class="code-image" src="@/assets/imgs/code.png" alt="">
        </div>
      </div>
      <div class="section2">
        <div class="name-content">
          <div class="name">关于我们</div>
          <div class="item" v-for="item in menuIcon" :key="item.name">
            <img class="icon" :src="item.icon" alt="">
            <div class="info">{{ item.name }}</div>
          </div>
        </div>
        <div class="info-text">
          迄今为止，百家云已在华北、华东、华南、华中等14地设有分公司、研发中心及办事处，拥有员工近500人。百家云为您提供以直点播、双师课堂、SaaS网校、BRTC等为核心的SaaS和PaaS产品，私有化网校、视频会议等云产品，及视频AI、智慧校园、智能学情等系统解决方案。
        </div>
      </div>
      <div class="line"></div>
      <div class="section3">
        <div class="map-content">
          <div class="name">公司地址</div>
          <img class="image" src="@/assets/imgs/map.png" alt="">
        </div>
        <div class="info">
          <div class="item" v-for="item in addInfo1" :key="item.name">
            <div class="name-desc">{{ item.name }}</div>
            <div class="info-desc" v-for="addr in item.list" :key="addr">{{ addr }}</div>
          </div>
        </div>
        <div class="info" style="margin-right: 26px">
          <div class="item" v-for="item in addInfo2" :key="item.name">
            <div class="name-desc">{{ item.name }}</div>
            <div class="info-desc" v-for="addr in item.list" :key="addr">{{ addr }}</div>
          </div>
        </div>
      </div>
      <div class="line" style="margin-top: 55px"></div>
      <div class="copy-right">Copyright@2014 - 2018 北京百家视联科技有限公司版权所有. 京公网安备11010802015210号 高新技术企业认定 | 京ICP备17043192号-1
      </div>
    </div>
  </div>
</template>

<script>
import ProductList from "../components/ProductList.vue";

export default {
  name: "HomePage",
  components: {ProductList},
  watch: {
    clientWidth(clientWidth) {
      let offset = clientWidth >= 1680 ? -150 : clientWidth >= 1366 ? -58 : 58;
      this.$refs.title.style.transform = `translateX(${(offset / 520 * 100 - (offset > 0 ? 60 : 100))}%)`
    }
  },
  data() {
    return {
      pageIdx: 1,
      pageSize: 1,
      clientWidth: 0,
      components: {ProductList},
      productList: [
        {
          name: '热门算法',
          en: 'POPULAR ALGORITHMS',
          icon: require('@/assets/imgs/hot.png'),
          list: [
            {
              name: '表情识别',
              img: require('@/assets/imgs/biaoqingshibie.jpg'),
              desc: '分析检测到的人脸的情绪，可识别愤怒、厌恶、恐惧、高兴、伤心、惊讶、无情绪等'
            },
            {
              name: '越界识别',
              img: require('@/assets/imgs/yuejieshibie.gif'),
              desc: '自动识别如输煤皮带、碎煤机等重点生产区域人员翻越、距离过近等行为'
            },
            {
              name: '火焰识别',
              img: require('@/assets/imgs/huoyanshibie.gif'),
              desc: '实时识别监控区内明火情况,立刻发出警报,提醒监控室查看,及时止损'
            },
            {
              name: '员工在岗识别',
              img: require('@/assets/imgs/yuangongzaigangshibie.gif'),
              desc: '基于计算机识别技术,配合现场摄像头,自动识别员工是否有脱岗行为,保证岗位安全'
            },
            {
              name: '手势识别',
              img: require('@/assets/imgs/shoushishibie.gif'),
              desc: '可识别24种常见手势，包括拳头、OK、比心、祈祷、我爱你、点赞、竖中指、数字等'
            },
            {
              name: '人流热度分析',
              img: require('@/assets/imgs/renliuredufenxi.jpg'),
              desc: '实时识别展区内客流热度情况，并自动生成统计报告'
            },
          ]
        },
        {
          name: '智慧工业',
          en: 'SMART INDUSTRY',
          icon: require('@/assets/imgs/factory.png'),
          list: [
            {
              name: '产线操作规范性识别',
              img: require('@/assets/imgs/chanxiancaozuoguifanshibie.gif'),
              desc: '实时识别监控区内员工操作情况， 并自动生成统计报告，提升产线操作质量'
            },
            {
              name: '钢卷入轨异常检测',
              img: require('@/assets/imgs/gangjuanruguiyichangjiance.gif'),
              desc: '实时识别监控区内钢卷异常情况，识别钢卷停止、脱落、卷曲等'
            },
            {
              name: '越界识别',
              img: require('@/assets/imgs/yuejieshibie.gif'),
              desc: '自动识别重点区域人员徘徊、爬墙、破坏铁丝网等危险行为识别'
            },
            {
              name: '二维码识别',
              img: require('@/assets/imgs/erweimashibie.png'),
              desc: '识别工业产品的二维码信息，用于生成和扫描多种二维码'
            },
            {
              name: '建筑图纸识别',
              img: require('@/assets/imgs/jianzhutuzhishibie.png'),
              desc: '提取建筑图纸的各项数据，可识别图纸中的房梁、柱、墙、配筋等多种信息'
            },
            {
              name: '安全帽识别',
              img: require('@/assets/imgs/anquanmaoshibie.jpg'),
              desc: '自动识别监测安全帽佩戴情况，实时预警，高效监管，提高厂区安全性'
            },
          ]
        },
        {
          name: '工业质检',
          en: 'INDUSTRIAL QUALITY INSPECTION',
          icon: require('@/assets/imgs/check.png'),
          list: [
            {
              name: '布匹缺陷识别',
              img: require('@/assets/imgs/bupiquexianshibie.png'),
              desc: '自动定位并识别图片中布匹瑕疵位置及类型,包括破洞、坏针、跑线等'
            },
            {
              name: '晶圆缺陷识别',
              img: require('@/assets/imgs/jingyuanquexianshibie.png'),
              desc: '适用于晶圆表面缺陷检测，支持多种晶圆缺陷类型识别'
            },
            {
              name: '电路板缺陷识别',
              img: require('@/assets/imgs/dianlubanquexianshibie.png'),
              desc: '识别电路板是否存在焊点过厚、不规则的情况，适用于电子零件、电子设备行业'
            },
            {
              name: '轨道漏水识别',
              img: require('@/assets/imgs/guidaoloushuishibie.png'),
              desc: '识别异常渗水、裂缝、锈蚀等多种隧道断面灾害'
            },
            {
              name: '表面缺陷识别',
              img: require('@/assets/imgs/biaomianquexian.png'),
              desc: '识别工业制品斑痕、粗糙度、裂纹、划伤、油污、锈蚀、氧化等工业 品缺陷'
            },
            {
              name: '木地板颜色/缺陷识别',
              img: require('@/assets/imgs/mudibanyanse.jpg'),
              desc: '识别木地板、复合板的颜色值'
            },
          ]
        },
        {
          name: '行为识别',
          en: 'BEHAVIOR RECOGNITION',
          icon: require('@/assets/imgs/step.png'),
          list: [
            {
              name: '打架识别',
              img: require('@/assets/imgs/dajiashibie.gif'),
              desc: '检测打架行为并即刻告警，管理人员及时采取安全措施，防止意外伤害。'
            },
            {
              name: '摔倒识别',
              img: require('@/assets/imgs/shuaidaoshibie.gif'),
              desc: '适用于自动识别如地铁手扶梯/楼梯、老幼活动区等公共场所人员摔倒行为'
            },
            {
              name: '越界识别',
              img: require('@/assets/imgs/yuejieshibie.gif'),
              desc: '自动识别如输煤皮带、碎煤机等重点生产区域人员翻越/距离过近等行为'
            },
            {
              name: '囚服/制服识别',
              img: require('@/assets/imgs/qiufu.jpg'),
              desc: '具有采集监控固定区域出现多人聚集的情况以实现突发事件预警'
            },
            {
              name: '攀爬识别',
              img: require('@/assets/imgs/panpashibie.gif'),
              desc: '对人员攀爬行为识别，适用于如分析区域内有人员作出类似攀爬的动作行为'
            },
            {
              name: '扔东西识别',
              img: require('@/assets/imgs/rengdongxishibie.gif'),
              desc: '识别人员扔东西、打砸等行为，分析区域内有人员作出类似扔东西、打砸的动作行为'
            },
          ]
        },
        {
          name: '交通运输',
          en: 'TRANSPORTATION',
          icon: require('@/assets/imgs/bus.png'),
          list: [
            {
              name: '集装箱识别',
              img: require('@/assets/imgs/jizhuangxiangshibie.png'),
              desc: '自动识别集装箱缺陷并报警反馈,保障运输安全,降低人工成本。'
            },
            {
              name: 'X光片绿产识别',
              img: require('@/assets/imgs/xguangpianlvchanshibie.png'),
              desc: '适用于检测假冒鲜活农产品运输的车辆场景'
            },
            {
              name: '疲劳驾驶识别',
              img: require('@/assets/imgs/pilaojiashishibie.png'),
              desc: '实时分析驾驶员的面部表情特征,识别到驾驶员的疲劳瞌睡状态、注意力分散状态'
            },
            {
              name: '大货车限行识别',
              img: require('@/assets/imgs/dahuochexianxingshibie.jpg'),
              desc: '自动对城市道路指定限制区域是否出现大货车进行识别'
            },
            {
              name: '车辆违停识别',
              img: require('@/assets/imgs/cheliangweitingshibie.jpg'),
              desc: '识别车辆进入违停区域或者违规占道时,可自动检测违停车辆的车牌号码'
            },
            {
              name: '路面裂缝识别',
              img: require('@/assets/imgs/lumianliefengshibie.jpg'),
              desc: '实时识别路面损坏情况检测道路中的深坑塌陷裂缝或者凹陷等情况,及时报警防范安全事故'
            },
          ]
        },
        {
          name: '通用算法',
          en: 'GENERAL ALGORITHM',
          icon: require('@/assets/imgs/ai.png'),
          list: [
            {
              name: '表情识别',
              img: require('@/assets/imgs/biaoqingshibie.jpg'),
              desc: '适应于大角度侧脸,遮挡,模糊,表情变化等各种实际环境。'
            },
            {
              name: '手势识别',
              img: require('@/assets/imgs/shoushishibie.gif'),
              desc: '识别图片中的手部位置和手势类型，可识别24种常见手势'
            },
            {
              name: '打架识别',
              img: require('@/assets/imgs/dajiashibie.gif'),
              desc: '检测打架行为并即刻告警，管理人员及时采取安全措施，防止意外伤害。'
            },
            {
              name: '摔倒识别',
              img: require('@/assets/imgs/shuaidaoshibie.gif'),
              desc: '适用于自动识别如地铁手扶梯/楼梯、老幼活动区等公共场所人员摔倒行为'
            },
            {
              name: '火焰识别',
              img: require('@/assets/imgs/huoyanshibie.gif'),
              desc: '实时识别监控区内明火情况,立刻发出警报,提醒监控室查看,及时止损'
            },
            {
              name: '员工在岗识别',
              img: require('@/assets/imgs/yuangongzaigangshibie.gif'),
              desc: '基于计算机识别技术,配合现场摄像头,自动识别员工是否有脱岗行为,保证岗位安全。'
            },
          ]
        }
      ],
      menu1: {
        name: '产品服务',
        list: [
          'AI算法平台',
          '巡龙平台',
          '应龙平台',
          '采集平台',
          '智能翻译'
        ]
      },
      menu2: {
        name: '解决方案',
        list: [
          '智慧学情',
          '智慧货运',
          '智慧质检',
          '智慧工厂'
        ]
      },
      menuIcon: [
        {
          icon: require('@/assets/imgs/micon1.png'),
          name: '百家云'
        },
        {
          icon: require('@/assets/imgs/micon2.png'),
          name: '招贤纳士'
        },
        {
          icon: require('@/assets/imgs/micon3.png'),
          name: '咨询中心'
        },
        {
          icon: require('@/assets/imgs/micon4.png'),
          name: '渠道加盟'
        },
      ],
      addInfo1: [
        {
          name: '总部：',
          list: [
            '北京市海淀区中关村软件园2号楼2C座'
          ]
        },
        {
          name: '研发中心：',
          list: [
            '武汉市高新大道426号华新总部基地B座4楼',
            '南京市雨花台区凤展路32号A1南栋24层'
          ]
        },
        {
          name: '办事处：',
          list: [
            '济南市高新区新泺大街1166号奥盛大厦3号楼20层2008'
          ]
        },
      ],
      addInfo2: [
        {
          name: '子公司：',
          list: [
            '上海市徐汇区桂林路406号华鑫中心1号楼11层1105室',
            '深圳市南山区前海自由贸易区香槟国际金融中心招商中心1309',
            '桂林经开区华为信息生态产业合作区创新大厦第3层354号',
            '南宁五象新区环球金融中心项目T1总部办公楼35层3505号',
            '河南省郑州市郑东新区绿地双子塔南塔705',
            '陕西省西安市灞桥区港务大道陆港大厦A座2507'
          ]
        },
      ]
    }
  },
  mounted() {
    this.sizeBox()
    window.onresize = () => {
      return (() => {
        this.sizeBox()
      })()
    };
  },
  methods: {
    sizeBox() {
      let s_width = window.clientWidth = document.body.clientWidth;
      this.clientWidth = window.clientWidth;

      let n_size = s_width >= 1680 ? 6 : s_width >= 1426 ? 5 : s_width >= 1280 ? 4 : 3
      if (n_size !== this.pageSize) {
        this.pageSize = n_size;
        this.scrollWidth = s_width >= 1680 ? 1620 : s_width >= 1426 ? 1350 : s_width >= 1280 ? 1080 : 810;
      }
    }
  }
}
</script>

<style scoped lang="scss">
.page {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #fbfafe;

  .head-content {
    position: relative;
    width: 100%;
    height: 420px;
    color: #FFFFFF;
    font-weight: 600;
    margin-bottom: 40px;

    .bg-img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: absolute;
      left: 0;
      top: 0;
    }

    .title-center {
      margin-left: 50%;
      width: 520px;
      transform: translateX(-130%);
      position: relative;

      .name-content {
        margin-top: 104px;
        height: 135px;

        .title-img {
          width: 60px;
          height: 90px;
          margin-bottom: 28px;
        }

        .name-img {
          width: 370px;
          height: 55px;
        }
      }

      .desc {
        width: 520px;
        height: 33px;
        opacity: 0.5;
        font-size: 22px;
        font-family: PingFangSC, PingFangSC-Regular;
        font-weight: 400;
        color: #ffffff;
        line-height: 33px;
        margin-top: 7px;
      }
    }
  }

  .center-content {
    margin-right: 20px;

    .product-content {
      display: flex;
      flex-direction: column;
      //width: 100%;
    }
  }

  .footer {
    margin-top: 100px;
    width: 100%;
    background: #01023d;
    display: flex;
    flex-direction: column;
    align-items: center;

    .section {
      display: flex;

      .icon-list {
        display: flex;
        flex-direction: column;
        align-items: center;

        .icon-content {
          display: flex;
          flex-direction: column;
          align-items: center;

          .icon1 {
            margin-top: 63px;
            width: 119px;
            height: 29px;
          }

          .icon2 {
            margin-top: 44px;
            width: 41px;
            height: 49px;
          }

          .icon3 {
            margin-top: 44px;
            width: 68px;
            height: 68px;
          }

          .icon4 {
            margin-top: 141px;
            width: 98px;
            height: 43px;
          }

          .icon5 {
            margin-top: 44px;
            width: 68px;
            height: 68px;
          }

          .name {
            margin-top: 16px;
            font-size: 14px;
            font-family: PingFangHK, PingFangHK-Light;
            font-weight: 300;
            text-align: left;
            color: #b1b1b1;
            line-height: 24px;
          }
        }
      }

      .menu-list {
        margin-left: 108px;
        margin-top: 60px;

        .title {
          height: 24px;
          font-size: 21px;
          font-family: PingFangSC, PingFangSC-Light;
          font-weight: 300;
          text-align: left;
          color: #ffffff;
          line-height: 24px;
          margin-bottom: 52px;
        }

        .menu-item {
          height: 24px;
          font-size: 14px;
          font-family: PingFangSC, PingFangSC-Light;
          font-weight: 300;
          text-align: left;
          color: #ffffff;
          line-height: 24px;
          margin-bottom: 9px;
        }
      }

      .code-content {
        margin-top: 60px;
        margin-left: 360px;

        .name {
          height: 24px;
          font-size: 21px;
          font-family: PingFangSC, PingFangSC-Light;
          font-weight: 300;
          text-align: left;
          color: #ffffff;
          line-height: 24px;
          margin-bottom: 13px;
        }

        .code-image {
          width: 164px;
          height: 164px;
        }
      }
    }

    .section2 {
      margin-top: 64px;
      display: flex;
      width: 1200px;
      flex-direction: column;

      .name-content {
        display: flex;

        .name {
          height: 24px;
          font-size: 21px;
          font-family: PingFangSC, PingFangSC-Light;
          font-weight: 300;
          text-align: left;
          color: #ffffff;
          line-height: 24px;
          margin-right: 316px;
        }

        .item {
          display: flex;
          align-items: center;
          margin-right: 128px;

          .icon {
            width: 32px;
            height: 32px;
            margin-right: 14px;
          }

          .info {
            height: 24px;
            font-size: 14px;
            font-family: PingFangSC, PingFangSC-Light;
            font-weight: 300;
            text-align: left;
            color: #ffffff;
            line-height: 24px;
          }

          &:last-child {
            margin-right: 0;
          }
        }
      }

      .info-text {
        margin-top: 17px;
        width: 1200px;
        height: 48px;
        font-size: 14px;
        font-family: PingFangSC, PingFangSC-Light;
        font-weight: 300;
        text-align: left;
        color: #ffffff;
        line-height: 24px;
      }
    }

    .line {
      width: 1200px;
      height: 1px;
      margin: 30px 0;
      background: rgba(255, 255, 255, 0.20);
    }

    .section3 {
      display: flex;

      .map-content {
        width: 340px;
        height: 170px;;
        position: relative;

        .image {
          width: 100%;
          height: 100%;
          position: absolute;
          left: 20px;
          top: 0;
        }

        .name {
          height: 24px;
          font-size: 21px;
          font-family: PingFangSC, PingFangSC-Light;
          font-weight: 300;
          text-align: left;
          color: #ffffff;
          line-height: 24px;
          position: absolute;
          left: 20px;
          top: 0px;
        }
      }

      .info {
        margin-left: 80px;

        .item {
          margin-bottom: 12px;

          .name-desc {
            font-size: 14px;
            font-family: PingFangSC, PingFangSC-Medium;
            font-weight: 500;
            text-align: left;
            color: #ffffff;
            line-height: 24px;
          }

          .info-desc {
            font-size: 14px;
            font-family: PingFangSC, PingFangSC-Thin;
            font-weight: 500;
            text-align: left;
            color: #ffffff;
            line-height: 24px;
          }
        }
      }
    }

    .copy-right {
      width: 1200px;
      font-size: 14px;
      font-family: PingFangSC, PingFangSC-Thin;
      font-weight: 200;
      text-align: left;
      color: #ffffff;
      line-height: 24px;
      padding-bottom: 20px;
    }
  }
}
</style>