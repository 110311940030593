<template>
  <div id="app">
    <HomePage/>
  </div>
</template>

<script>
import HomePage from "./pages/HomePage.vue";
export default {
  name: 'App',
  components: {
    HomePage
  },
  created() {
    this.testMSite();
  },
  methods:{
    testMSite() {
      const ua = navigator.userAgent;
      const agent = ["Android", "iPhone", "SymbianOS", "Windows Phone", "iPad", "iPod"];
      const len = agent.length
      let mSite=false;
      for (let i = 0; i < len; i++) {
        if (ua.indexOf(agent[i]) > 0) {
          mSite=true;
        }
      }
      if (mSite){
        window.location.href = 'https://ai.baijiayun.com/m/';
      }
    },
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
