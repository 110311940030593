<template>
  <div class="product-content" :style="{width:scrollWidth+'px'}">
    <div class="title">
      <div class="title-content">
        <img class="icon-img" :src="item.icon" alt="">
        <div class="name-text">{{ item.name }}</div>
        <div class="en-text">{{ item.en }}</div>
      </div>
      <div class="navigation" v-if="pageSum!==1">
        <img class="icon" :src="leftArr" ref="leftArr" @click="prePage" alt="">
        <div class="info">{{ this.pageIdx }}/{{ pageSum }}</div>
        <img class="icon" :src="rightArr" ref="rightArr" @click="nextPage" alt="">
      </div>
    </div>
    <div class="list-content" :style="{width:scrollWidth+'px'}" ref="list">
      <div class="item" v-for="(product,index) in item.list" :key="index">
        <img class="image-content" :src="product.img" alt="">
        <div class="name-text">{{ product.name }}</div>
        <div class="desc">{{ product.desc }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProductList",
  props: {
    item: Object,
    pageSize:Number,
    scrollWidth:Number
  },
  data() {
    return {
      clientWidth: 0,
      pageIdx: 1,
      leftActive: false,
      rightActive: false
    }
  },
  computed: {
    pageSum() {
      return Math.ceil(this.item.list.length / this.pageSize);
    },
    leftArr() {
      if (this.pageIdx > 1 && this.pageSize > 1) {
        if (this.leftActive) {
          return require('@/assets/imgs/left-a.png')
        }
        return require('@/assets/imgs/left-n.png')
      } else {
        return require('@/assets/imgs/left-f.png')
      }
    },
    rightArr() {
      if (this.pageIdx < this.pageSum && this.pageSize > 1) {
        if (this.rightActive) {
          return require('@/assets/imgs/right-a.png')
        }
        return require('@/assets/imgs/right-n.png')
      } else {
        return require('@/assets/imgs/right-f.png')
      }
    }
  },
  watch:{
    pageSum(newPage){
      if(this.pageIdx>newPage){
        this.pageIdx=newPage;
      }
    }
  },
  mounted() {
    this.$refs.leftArr.addEventListener('mouseenter', () => {
      this.leftActive = true
    })
    this.$refs.leftArr.addEventListener('mouseout', () => {
      this.leftActive = false
    })
    this.$refs.rightArr.addEventListener('mouseenter', () => {
      this.rightActive = true
    })
    this.$refs.rightArr.addEventListener('mouseout', () => {
      this.rightActive = false
    })
  },
  timer: null,
  methods: {
    nextPage() {
      if (this.pageIdx < this.pageSum) {
        this.pageIdx = this.pageIdx + 1;
        this.autoScroll()
      }
    },
    prePage() {
      if (this.pageIdx > 1) {
        this.pageIdx = this.pageIdx - 1;
        this.autoScroll()
      }
    },
    autoScroll() {
      this.$refs.list.scrollTo({
        left: this.scrollWidth * (this.pageIdx - 1),
        top: 0,
        behavior: 'smooth'
      })
    }

  }
}
</script>

<style scoped lang="scss">
.product-content {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;

  .title {
    padding: 10px 0 6px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .title-content {
      display: flex;
      align-items: center;

      .icon-img {
        width: 24px;
        height: 24px;
      }

      .name-text {
        width: 72px;
        height: 25px;
        font-size: 18px;
        font-family: PingFangSC, PingFangSC-Regular;
        font-weight: 400;
        text-align: left;
        color: #333333;
        line-height: 25px;
        margin-left: 4px;
      }

      .en-text {
        margin-left: 4px;
        height: 17px;
        font-size: 12px;
        font-family: PingFangSC, PingFangSC-Regular;
        font-weight: 400;
        text-align: left;
        color: #999999;
        line-height: 17px;
      }
    }

    .navigation {
      display: flex;
      align-items: center;

      .icon {
        width: 24px;
        height: 24px;
      }

      .info {
        font-size: 16px;
        font-family: PingFangSC, PingFangSC-Regular;
        font-weight: 400;
        color: #333333;
      }
    }
  }

  .list-content {
    width: 100%;
    display: flex;
    padding: 12px 10px 0;
    overflow-x: hidden;

    .item {
      width: 270px;
      height: 240px;
      box-sizing: border-box;
      padding: 10px;
      transition: all .2s linear;

      &:hover {
        width: 270px;
        height: 240px;
        border-radius: 8px;
        box-shadow: 0 0 10px 0 rgba(6, 10, 24, 0.20);
        transform: translateY(-10px);
        transition: all .2s linear;
      }

      .image-content {
        width: 250px;
        height: 140px;
      }

      .name-text {
        width: 250px;
        margin-top: 8px;
        height: 20px;
        font-size: 14px;
        font-family: PingFangSC, PingFangSC-Regular;
        font-weight: 400;
        color: #333333;
        line-height: 20px;
        text-overflow: ellipsis;
        -webkit-box-orient: vertical;
        overflow: hidden;
        -webkit-line-clamp: 1;
        display: -webkit-box;
        white-space: normal;
      }

      .desc {
        width: 250px;
        margin-top: 6px;
        font-size: 12px;
        font-family: PingFangSC, PingFangSC-Regular;
        font-weight: 400;
        color: #999999;
        line-height: 17px;
        text-overflow: ellipsis;
        -webkit-box-orient: vertical;
        overflow: hidden;
        -webkit-line-clamp: 2;
        display: -webkit-box;
        white-space: normal;
      }
    }
  }

  ::-webkit-scrollbar {
    display: none
  }
}
</style>